<template>
  <div v-if="userResult">
    <h1>
      {{ userResult.user.firstName }}
      {{ userResult.user.lastName }}
      ({{ userResult.user.gender }})
    </h1>

    <p>
      <router-link :to="{ name: 'EditUser', params: { id: userResult.user.id } }">
        Bewerk
      </router-link>
    </p>

    <p v-if="userResult.user.birthdate">
      <strong>Geboortedatum:</strong> {{ userResult.user.birthdate }}
    </p>

    <h3>Adres</h3>

    <p>
      {{ userResult.user.streetWithHouseNumber }}<br>
      {{ userResult.user.zipcode }} {{ userResult.user.town }}<br>
      <em>{{ userResult.user.province }}</em>
    </p>

    <template v-if="userResult.user.email || userResult.user.phonenumber">
      <h3>Contactgegevens</h3>

      <p class="contact-options">
        <a v-if="userResult.user.email" :href="mailtoLink(userResult.user)">
          Stuur e-mail
        </a>

        <span class="phone-number" v-if="userResult.user.phonenumber">
          {{ userResult.user.phonenumber }}
        </span>

        <span class="mobile-phone-number" v-if="userResult.user.mobilePhonenumber">
          {{ userResult.user.mobilePhonenumber }}
        </span>
      </p>
    </template>

    <template v-if="userResult.user.iban || userResult.user.bic">
      <h3>Bankgegevens</h3>

      <p v-if="userResult.user.iban">
        <strong>IBAN:</strong> {{ userResult.user.iban }}
      </p>

      <p v-if="userResult.user.bic">
        <strong>BIC:</strong> {{ userResult.user.bic }}
      </p>
    </template>

    <h3>Lidmaatschappen</h3>

    <ul>
      <li v-for="membership in userResult.user.memberships" :key="membership.id">
        {{ membership.group.name }} ({{ membership.role }})
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useQuery } from 'villus';

export default defineComponent({
  name: 'UserDetails',

  props: {
    id: String,
    isAdmin: Boolean,
  },

  setup(props) {
    const User = `
      query {
        user(id:${props.id}) {
          id
          email
          gender
          firstName
          lastName
          zipcode
          streetWithHouseNumber
          town
          province
          country
          phonenumber
          mobilePhonenumber
          iban
          bic
          birthdate
          memberships {
            id
            role
            group {
              name
            }
          }
        }
      }
    `;

    const mailtoLink = (user: User) => `mailto:${user.email}`;

    const { data: userResult } = useQuery({ query: User, cachePolicy: 'network-only' });

    return { userResult, mailtoLink };
  },
});
</script>

<style lang="scss" scoped>
.contact-options > * {
  display: block;
}
</style>
