
import { defineComponent } from 'vue';
import { useQuery } from 'villus';

export default defineComponent({
  name: 'UserDetails',

  props: {
    id: String,
    isAdmin: Boolean,
  },

  setup(props) {
    const User = `
      query {
        user(id:${props.id}) {
          id
          email
          gender
          firstName
          lastName
          zipcode
          streetWithHouseNumber
          town
          province
          country
          phonenumber
          mobilePhonenumber
          iban
          bic
          birthdate
          memberships {
            id
            role
            group {
              name
            }
          }
        }
      }
    `;

    const mailtoLink = (user: User) => `mailto:${user.email}`;

    const { data: userResult } = useQuery({ query: User, cachePolicy: 'network-only' });

    return { userResult, mailtoLink };
  },
});
